import { readable, writable } from "svelte/store";

export const gapi_oauth_client_id = readable("396158002778-lcigsl2plsn0q9e43989u4b9casl83qk.apps.googleusercontent.com");
export const gapi_oauth_scopes = readable("https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/books");

export const gapi_discovery_docs = readable(["https://people.googleapis.com/$discovery/rest?version=v1", "https://content.googleapis.com/discovery/v1/apis/books/v1/rest"]);
export const gapi_api_key = readable("AIzaSyAiXBt-hcIt3NnmtZwJ_Qn2cSsEABfPXd4");

export const user_stores_prefix = readable("GAMERATOOR_BOOK_LIBRARY_");
export let user_stores = writable({});

export let current_user = writable(null);
export let current_bookshelf = writable(null);

