<script>
	import { onMount } from "svelte";

	// your script goes here
	import { current_bookshelf, user_stores, current_user } from "../stores";

	let loading = false;

	let books = [];

	function get() {
		loading = true;

		gapi.client.books.mylibrary.bookshelves.volumes
			.list({
				shelf: $current_bookshelf,
				maxResults: 99999,
				projection: "full",
			})
			.then(
				function (response) {
					// Handle the results here (response.result has the parsed body).
					books = response.result.items;
					loading = false;

					$user_stores[$current_user.id].books = {};
					$user_stores[$current_user.id].books[$current_bookshelf] = {};

					books.forEach((el) => {
						$user_stores[$current_user.id].books[$current_bookshelf][el.id] = el;
					});
				},
				function (err) {
					console.error("Execute error", err);
				}
			);
	}

	onMount(() => {
		try {
			if (typeof $user_stores[$current_user.id].books[$current_bookshelf] != "undefined") {
				loading = true;
				Object.values($user_stores[$current_user.id].books[$current_bookshelf]).forEach((el) => books.push(el));
				books = books;
				loading = false;
			} else {
				get();
			}
		} catch (error) {}
	});
</script>

<!-- markup (zero or more items) goes here -->

<div class="container">
	<h2>Bookshelf list "{$user_stores[$current_user.id].bookshelves[$current_bookshelf].title}"</h2>
	<button class="btn btn-secondary" on:click={() => ($current_bookshelf = null)}>Chose another bookshelf</button>

	{#if loading}
		<p>Loading books...</p>
		<div class="align-items-center d-flex justify-content-center">
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	{:else}
		<button class="btn btn-primary" on:click={() => get()}>Refresh</button>
		<div class="d-flex flex-column gap-3">
			{#each books as book}
				<div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
					<div class="col p-4 d-flex flex-column position-static">
						<strong class="d-inline-block mb-2 text-primary">
							{#each book.volumeInfo.authors as author}
								{author}&nbsp;
							{/each}
						</strong>
						<h3 class="mb-0">{book.volumeInfo.title}</h3>
						<div class="mb-1 text-muted">{book.volumeInfo.publishedDate}</div>
						<p class="card-text mb-auto">{book.volumeInfo.description}</p>
						<a href={book.accessInfo.webReaderLink}>Continue reading</a>
					</div>
					<div class="col-auto d-none d-lg-block">
						<img src="https://books.google.com/books/content?id={book.id}&printsec=frontcover&img=1&zoom=1&uvs=3&source=gbs_api&authuser=0&h=400&usc=0" alt="" />
					</div>
				</div>
			{:else}
				no books found
			{/each}
		</div>
	{/if}
</div>

<style>
	/* your styles go here */
</style>
