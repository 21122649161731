<script>
	import { onDestroy, onMount } from "svelte";
	import { current_user, current_bookshelf, gapi_api_key, gapi_discovery_docs, gapi_oauth_client_id, gapi_oauth_scopes, user_stores, user_stores_prefix } from "./stores";

	import PickBookshelf from "./components/PickBookshelf.svelte";
	import Bookshelf from "./components/Bookshelf.svelte";

	if (localStorage.getItem($user_stores_prefix + "user_stores") != null) {
		$user_stores = JSON.parse(localStorage.getItem($user_stores_prefix + "user_stores"));
	}

	const unsubscribe = user_stores.subscribe((value) => {
		localStorage.setItem($user_stores_prefix + "user_stores", JSON.stringify(value));
	});

	const curr_bs_sub = current_bookshelf.subscribe((value) => {
		if ($current_user != null) {
			if (typeof $user_stores[$current_user.id] == "undefined") {
				$user_stores[$current_user.id] = {};
			}

			$user_stores[$current_user.id].selected_bookshelf = value;

			$user_stores = $user_stores;
		}
	});

	onDestroy((e) => {
		unsubscribe(e);
		curr_bs_sub(e);
	});

	let loading = false;
	let logged_in = false;

	let application_is_loaded = false;

	// let loadingModal = null;

	const google_api = {
		handleClientLoad: () => {
			application_load_progress.set(1);
			loading = true;
			gapi.load("client:auth2", google_api.initClient);
		},

		initClient: () => {
			gapi.client
				.init({
					apiKey: $gapi_api_key,
					discoveryDocs: $gapi_discovery_docs,
					clientId: $gapi_oauth_client_id,
					scope: $gapi_oauth_scopes,
				})
				.then(function () {
					console.log("client init success");
					// Listen for sign-in state changes.
					gapi.auth2.getAuthInstance().isSignedIn.listen(google_api.updateSigninStatus);

					// Handle the initial sign-in state.
					google_api.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());

					loading = false;
					application_is_loaded = true;
				});
		},

		updateSigninStatus: (isSignedIn) => {
			console.log("signin status updated: ", isSignedIn);
			logged_in = isSignedIn;

			if (isSignedIn) {
				let obj = gapi.auth2.getAuthInstance().currentUser.get();
				$current_user = {
					id: obj.getId(),
					full_name: obj.Du.tf,
					email: obj.Du.tv,
					fname: obj.Du.VX,
					lname: obj.Du.iW,
					image: obj.Du.eN.split("=")[0],
				};

				try {
					if (typeof $user_stores[$current_user.id].selected_bookshelf != "undefined") {
						$current_bookshelf = $user_stores[$current_user.id].selected_bookshelf;
					}
				} catch (error) {}
			}
		},

		sign_in: () => {
			if (typeof gapi.auth2 === "undefined") {
				alert("please load the application first");
			} else {
				gapi.auth2
					.getAuthInstance()
					.signIn()
					.catch((err) => {
						if (err.error == "popup_blocked_by_browser") {
							alert("Your browser has blocked the signin popup. Please allow popups before continuing");
						} else if (err.error == "popup_closed_by_user") {
							console.log("user closed login popup");
						} else {
							console.error("GOOGLE API AUTHORIZATION API ERROR \n\n", err.error);
						}
					});
			}
		},

		sign_out: () => {
			if (typeof gapi.auth2 === "undefined") {
				alert("please load the application first");
			} else {
				gapi.auth2.getAuthInstance().signOut();
			}
		},
	};

	import { tweened } from "svelte/motion";
	import { cubicOut } from "svelte/easing";

	const application_load_progress = tweened(0, {
		duration: 4000,
		easing: cubicOut,
	});

	let application_load_interval = setInterval(() => {
		try {
			if (application_is_loaded || loading) {
				clearInterval(application_load_interval);
			} else {
				google_api.handleClientLoad();
			}
		} catch (error) {}
	}, 200);
</script>

<!--  -->

{#if loading}
	<div class="position-absolute align-items-center d-flex justify-content-center">
		<div class="spinner-border" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
	</div>
{/if}

{#if application_is_loaded}
	<div class="container">
		{#if logged_in}
			<p>Logged In as <strong>{$current_user.full_name}</strong> <a href="#sign_out" on:click|preventDefault={() => google_api.sign_out()} class="link">Sign Out</a></p>

			{#if $current_bookshelf == null}
				<PickBookshelf />
			{:else}
				
				<Bookshelf></Bookshelf>
			{/if}
		{:else}
			<span>Logged Out</span>
			<button class="btn btn-primary" on:click={() => google_api.sign_in()}>Sign In</button>
		{/if}
	</div>
{:else}
	<div class="w-100 ha100 d-flex justify-content-center align-items-center flex-column gap-4">
		<h3>Loading Application...</h3>

		<div class="progress" style="max-width: 20rem; width: 100%">
			<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={$application_load_progress} aria-valuemin="0" aria-valuemax="1" style="width: {$application_load_progress * 100}%" />
		</div>
	</div>
{/if}

<style>
</style>
