<script>
	import { current_bookshelf, user_stores, current_user } from "../stores";

	let bookshelves = [];

	let loading = true;

	gapi.client.books.mylibrary.bookshelves.list({}).then(
		function (response) {
			bookshelves = response.result.items;
			loading = false;

			$user_stores[$current_user.id].bookshelves = {};

			bookshelves.forEach((el) => {
				$user_stores[$current_user.id].bookshelves[el.id] = {
					title: el.title,
					access: el.access,
					volumeCount: el.volumeCount,
					volumesLastUpdated: el.volumesLastUpdated,
				};
			});
		},
		function (err) {
			console.error("Execute error", err);
		}
	);

	function choose_bookshelf(id) {
		$current_bookshelf = id;
		$user_stores[$current_user.id].selected_bookshelf = id;
	}
</script>

<div class="container">
	<h2>Pick the bookshelf you want to access</h2>
	{#if loading}
		<p>Loading shelves...</p>
		<div class="align-items-center d-flex justify-content-center">
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	{:else}
		<ul class="list-group">
			{#each bookshelves as bs}
				<button class="list-group-item d-flex justify-content-between align-items-start text-start" on:click={() => choose_bookshelf(bs.id)}>
					<div class="ms-2 me-auto">
						<div class="fw-bold">{bs.title}</div>
						<span>Last Updated: {typeof bs.volumesLastUpdated == "undefined" ? "unknown" : bs.volumesLastUpdated}</span>
					</div>
					{#if bs.volumeCount}
						<span class="badge bg-primary rounded-pill">{bs.volumeCount}</span>
					{/if}
				</button>
			{:else}
				<li class="list-group-item d-flex justify-content-between align-items-start">
					<div class="ms-2 me-auto">no bookshelves found. error?</div>
				</li>
			{/each}
		</ul>
	{/if}
</div>

<style>
	/* your styles go here */
</style>
